body {
  padding: 0 2rem;
  margin: 0 2rem;
}

body {
  font-family: 'Press Start 2P', sans-serif;
}

@media (max-width: 768px) {
  body {
    margin: 2rem 0.5rem;
    padding: 0;
  }
}

button {
  margin: 0.5rem !important;
}

.nes-icon {
  margin-right: 4rem !important;
}